import { CopyOutlined, StopOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  message,
  Popconfirm,
  Space,
  Table,
  TableColumnType,
  Tag,
  Typography,
} from 'antd';
import { NoticeType } from 'antd/es/message/interface';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { IReplenishSuggestionItem } from '../../../@types/replenish-suggestion-item.interface';
import { IReplenishSuggestion } from '../../../@types/replenish-suggestion.interface';
import { SuggestionStatus } from '../../../@types/suggestion-status.enum';
import { ReplenishSuggestionsApi } from '../../../api';
import ReplenishSuggestionExpandedRow from './replenis-suggestion-expanded-row.component';
interface ReplenishSuggestionsTableProps {
  dataSource: IReplenishSuggestion[];
  replenishSuggestionsApi: ReplenishSuggestionsApi;
  fetchApiData: () => Promise<void>;
}
const ReplenishSuggestionsTable: React.FC<ReplenishSuggestionsTableProps> = (
  props,
) => {
  const isEditing = (record: IReplenishSuggestion) => record.id === editingKey;
  const [data, setData] = useState<IReplenishSuggestion[]>(props.dataSource);
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const messageBoxKey = 'message-box-top';

  useEffect(() => {
    setData(props.dataSource);
  }, [props.dataSource]);

  const onDueDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
    form.setFieldValue('dueDate', dateString);
  };
  const isOverDue = (date: Date) => {
    const dueDate = dayjs(date);
    return dueDate.isBefore(dayjs());
  };
  const cancelEditMode = () => {
    setEditingKey('');
  };
  const showMessageBox = (
    type: NoticeType,
    message: string,
    durationInSeconds = 2,
  ) => {
    messageApi.open({
      key: messageBoxKey,
      type: type,
      content: message,
      duration: durationInSeconds,
    });
  };

  const editReplenishSuggestion = (record: IReplenishSuggestion) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id ?? '');
    const ids = [...expandedRowKeys, record.id];
    setExpandedRowKeys(ids);
  };
  const cancelReplenishSuggestion = async (rs: IReplenishSuggestion) => {
    showMessageBox('loading', 'Cancelling Replenish Suggestion');
    const res =
      await props.replenishSuggestionsApi.cancelReplenishSuggestion(rs);
    await props.fetchApiData();
    if (!res) {
      showMessageBox('error', 'Failed to modify Replenish Suggestion Item');
      return;
    }
    showMessageBox('success', 'Cancelled Replenish Suggestion');
  };
  const cancelReplenishSuggestionItem = async (
    rsi: IReplenishSuggestionItem,
  ) => {
    showMessageBox('loading', 'Cancelling Replenish Suggestion Item');
    const res =
      await props.replenishSuggestionsApi.cancelReplenishSuggestionItem(rsi);
    await props.fetchApiData();
    if (!res) {
      showMessageBox('error', 'Failed to modify Replenish Suggestion Item');
      return;
    }
    showMessageBox('success', 'Cancelled Replenish Suggestion Item');
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        showMessageBox('success', 'Copied to clipboard');
      },
      (err) => {
        showMessageBox('error', 'Failed to copy');
      },
    );
  };

  type EditableColumnsType<RecordType> = (TableColumnType<RecordType> & {
    editable: boolean;
  })[];
  const columns: EditableColumnsType<IReplenishSuggestion> = [
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      editable: true,
      filters: [
        { text: 'Past (Already Sent)', value: 'past' },
        { text: 'Future Replenish Suggestions', value: 'future' },
      ],
      sorter: (a, b) => dayjs(a.dueDate).unix() - dayjs(b.dueDate).unix(),
      defaultFilteredValue: ['future'],
      onFilter: (value, record) => {
        const dueDate = dayjs(record.dueDate);
        if (value === 'past') {
          return dueDate.isBefore(dayjs());
        } else if (value === 'future') {
          return (
            dueDate.isSame(dayjs(), 'day') || dueDate.isAfter(dayjs(), 'day')
          );
        }
        return true;
      },
      render: (_: any, rs: IReplenishSuggestion) => {
        const dueDate = dayjs(rs.dueDate);
        const daysUntilDue = dueDate
          .startOf('day')
          .diff(dayjs().startOf('day'), 'day');
        return isEditing(rs) ? (
          <DatePicker
            showTime
            defaultValue={dueDate}
            disabled={true} // add !isEditing(rs) to enable editing
            format={'YYYY-MM-DDTHH:mm:ss'}
            onChange={onDueDateChange}
          />
        ) : (
          <Typography.Text
            className={`${isOverDue(rs.dueDate) || rs.status !== SuggestionStatus.PENDING ? 'past-due-row' : ''}`}
          >
            {dueDate.format('DD.MM.YYYY HH:mm:ss')}{' '}
            {daysUntilDue === 0
              ? '(today)'
              : daysUntilDue < 0
                ? `(${Math.abs(daysUntilDue)} day${Math.abs(daysUntilDue) > 1 ? 's' : ''} ago)`
                : `(in ${daysUntilDue} day${daysUntilDue > 1 ? 's' : ''})`}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Total Pieces',
      key: 'totalPieces',
      editable: false,
      render: (_: any, rs: IReplenishSuggestion) => (
        <Typography.Text
          className={`${isOverDue(rs.dueDate) || rs.status !== SuggestionStatus.PENDING ? 'past-due-row' : ''}`}
        >
          {rs.replenishSuggestionItems
            .map((rs) => rs.pieces)
            .reduce((prev, current) => (prev += current))}
        </Typography.Text>
      ),
    },
    {
      title: 'Package Sizes',
      key: 'totalAmount',
      editable: false,
      render: (_: any, rs: IReplenishSuggestion) => (
        <Typography.Text
          className={`${isOverDue(rs.dueDate) || rs.status !== SuggestionStatus.PENDING ? 'past-due-row' : ''}`}
        >
          {[
            ...Array.from(
              new Set(
                rs.replenishSuggestionItems.map(
                  (rs) => rs.baseUnitAmount + ' ' + rs.baseUnit,
                ),
              ),
            ),
          ].join(', ')}
        </Typography.Text>
      ),
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      editable: false,
      render: (_: any, rs: IReplenishSuggestion) => (
        <Typography.Text
          code
          className={`${isOverDue(rs.dueDate) || rs.status !== SuggestionStatus.PENDING ? 'past-due-row' : ''}`}
        >
          {rs.userId}
        </Typography.Text>
      ),
    },
    {
      title: 'RS User ID',
      dataIndex: 'externalUserId',
      key: 'externalUserId',
      editable: false,
    },

    {
      title: 'Suggestion Status',
      key: 'suggestionStatus',
      editable: false,
      filters: [
        {
          text: 'Pending',
          value: SuggestionStatus.PENDING,
        },
        {
          text: 'Delivered',
          value: SuggestionStatus.DELIVERED,
        },
        { text: 'Led to new order', value: SuggestionStatus.LED_TO_ORDER },
        { text: 'Cancelled', value: SuggestionStatus.CANCELLED },
        { text: 'Cancelled due to opt-out', value: SuggestionStatus.CANCELLED_DUE_TO_OPT_OUT },
        { text: 'Obsolete', value: SuggestionStatus.OBSOLETE_DUE_TO_NEW_ORDER },
        { text: 'Error', value: SuggestionStatus.ERROR },
      ],
      onFilter: (value, record) => record.status === value,
      render: (_: any, rs: IReplenishSuggestion) => (
        <Tag
          color={
            rs.status === SuggestionStatus.DELIVERED
              ? 'green'
              : rs.status === SuggestionStatus.LED_TO_ORDER
              ? '#2c7e0a' //Darker grey in same color shade as delivered
              : rs.status === SuggestionStatus.PENDING
                ? 'orange'
                : rs.status === SuggestionStatus.OBSOLETE_DUE_TO_NEW_ORDER
                ? '#a9a9a9' //Grey
                : 'red'
          }
          key={'suggestionStatus-' + rs.id}
        >
          {rs.status}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      editable: false,
      render: (_: any, record: IReplenishSuggestion) => {
        const isInEditMode = isEditing(record);
        return (
          <Space>
            {isInEditMode ? (
              <span>
                <Typography.Link
                  onClick={() => saveReplenishSuggestion(record.id)}
                  style={{ marginInlineEnd: 8 }}
                >
                  Save
                </Typography.Link>
                <Popconfirm title="Sure to cancel?" onConfirm={cancelEditMode}>
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : record.status === SuggestionStatus.PENDING ? (
              <>
                {/* <Button
                  color="default"
                  variant="outlined"
                  icon={<EditOutlined />}
                  disabled={editingKey</Popconfirm> !== ''}
                  onClick={() => editReplenishSuggestion(record)}
                  style={{ color: '#4096ff', borderColor: '#4096ff' }}
                >
                  Edit
                </Button> */}

                <Button
                  icon={<CopyOutlined />}
                  onClick={() => copyToClipboard(record.id)}
                  title="Copy Replenish Suggestion ID"
                />
                <Popconfirm
                  title="Really cancel this Replenish Suggestion?"
                  onConfirm={() => cancelReplenishSuggestion(record)}
                >
                  <Button
                    danger
                    icon={<StopOutlined />}
                    disabled={editingKey !== ''}
                    title="Cancel Replenish Suggestion"
                  />
                </Popconfirm>
              </>
            ) : (
              <Button
                icon={<CopyOutlined />}
                onClick={() => copyToClipboard(record.id)}
                title="Copy Replenish Suggestion ID"
              />
            )}
          </Space>
        );
      },
    },
  ];

  const mergedColumns: EditableColumnsType<IReplenishSuggestion> = columns.map(
    (col) => {
      if (!col.editable) {
        return col;
      }
      // ODO: Fix this once edit replenish suggestion is implemented
      return col;
      // return {
      //   ...col,
      //   onCell: (record: IReplenishSuggestion) => ({
      //     record,
      //     inputype: col.dataIndex === 'dueDate' ? 'date' : 'text',
      //     dataIndex: col.dataIndex,
      //     title: col.title?.toString(),
      //     editing: isEditing(record).toString(),
      //   }),
      // };
    },
  );

  const saveReplenishSuggestionItem = async (rsi: IReplenishSuggestionItem) => {
    showMessageBox('loading', 'Modifying Replenish Suggestion Item');
    const res =
      await props.replenishSuggestionsApi.modifyReplenishSuggestionItemAmount(
        rsi,
      );
    await props.fetchApiData();
    if (!res) {
      showMessageBox('error', 'Failed to modify Replenish Suggestion Item');
      return;
    }
    showMessageBox('success', 'Modified Replenish Suggestion Item');
  };
  const saveReplenishSuggestion = async (id: string) => {
    try {
      const row = (await form.validateFields()) as IReplenishSuggestion;

      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const handleExpand = (expanded: boolean, record: IReplenishSuggestion) => {
    const ids = expanded
      ? [...expandedRowKeys, record.id]
      : expandedRowKeys.filter((id) => id !== record.id);
    setExpandedRowKeys(ids);
  };

  const expandedRowRender = (record: IReplenishSuggestion) => (
    <ReplenishSuggestionExpandedRow
      record={record}
      cancelReplenishSuggestionItem={cancelReplenishSuggestionItem}
      onSaveReplenishSuggestionItem={saveReplenishSuggestionItem}
      copyToClipboard={copyToClipboard}
    />
  );

  return (
    <>
      {contextHolder}
      <Table
        dataSource={data}
        columns={mergedColumns}
        className="replenish-suggestions-table"
        expandable={{
          expandedRowRender,
          expandedRowKeys,
          onExpand: handleExpand,
        }}
        rowClassName={(record) => {
          if (expandedRowKeys.includes(record.id)) {
            return 'expanded-row';
          }
          if (
            isOverDue(record.dueDate) ||
            record.status !== SuggestionStatus.PENDING
          ) {
            return 'past-due-row';
          }
          return '';
        }}
        rowKey={(record) => record.id}
      />
    </>
  );
};

export default ReplenishSuggestionsTable;
