import React, { useState, useEffect } from "react";
import { Modal } from "antd";

const OfflineStatus: React.FC = () => {
  const [isOnline, setOnlineStatus] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const setFromEvent = (event: Event) => {
      if (event.type === "online") {
        setOnlineStatus(true);
      } else if (event.type === "offline") {
        setOnlineStatus(false);
      }
    };

    window.addEventListener("online", setFromEvent);
    window.addEventListener("offline", setFromEvent);

    return () => {
      window.removeEventListener("online", setFromEvent);
      window.removeEventListener("offline", setFromEvent);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [isOnline]);

  return (
    <>
      <Modal
        title="You are currently offline."
        open={isModalOpen}
        footer={null}
        closable={false}
      >
        Please go back online to continue.
      </Modal>
    </>
  );
};

export default OfflineStatus;
