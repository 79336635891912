import { Row, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import logo from '../../assets/images/logo.svg';
import splash1 from '../../assets/images/splash1.svg';
import splash2 from '../../assets/images/splash2.svg';
import splash3 from '../../assets/images/splash3.svg';
import './styles.scss';

interface SplashProps {
  children?: React.ReactNode;
  redirect: boolean;
}

const Splash: React.FC<SplashProps> = ({ children, redirect }) => {
  const redirectLink = '/replenish-suggestions';

  if (redirect) {
    useEffect(() => {
      setTimeout(() => {
        const splashScreenEl = document.getElementById('splashScreenWrapper');
        if (splashScreenEl) {
          splashScreenEl.classList.remove('visible');
          splashScreenEl.classList.add('hidden');
          setTimeout(() => {
            window.location.replace(redirectLink);
          }, 1000);
        }
      }, 500);
    }, []);
  }

  return (
    <div className="root">
      <div id="splashScreenWrapper" className="splash-screen-wrapper visible">
        {children}

        <Row className="first-splash-img">
          <img src={splash1} alt="splash1" />
        </Row>
        <Row className="second-splash-img">
          <img src={splash2} alt="splash2" />
        </Row>
        <Row className="third-splash-img">
          <img src={splash3} alt="splash3" />
        </Row>
        <Row className="splash-screen-logo-wrapper">
          {/* <Col span={24}> */}
          <img src={logo} alt="kiip" className="logo" />
          {/* </Col> */}
        </Row>
        <Row>
          <Typography.Text>
            <Space>
              © 2024 kiip –
              <Typography.Link href="mailto:service@kiip.ai">
                service@kiip.ai
              </Typography.Link>
              – Linienstr. 86, 10115 Berlin –
              <Typography.Link href="https://www.kiip.ai/imprint">
                Impressum
              </Typography.Link>
              {' – '}
              <Typography.Link href="https://www.kiip.ai/datenschutz">
                Datenschutz
              </Typography.Link>
            </Space>
          </Typography.Text>
        </Row>
        <Row className="footer-text">
          <Typography.Text>
            <Space></Space>
          </Typography.Text>
        </Row>
      </div>
    </div>
  );
};

export default Splash;
