import { Button } from 'antd';
import { useAuth } from 'oidc-react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useLoginService from './api/useLoginService';
import { UserContext } from './context/userContext';

export type ProtectedRouteProps = {
  outlet: JSX.Element;
};

export default function ProtectedRoute({ outlet }: ProtectedRouteProps) {
  const { t } = useTranslation('login');
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const auth = useAuth();
  const loginService = useLoginService();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  if (!loggedIn) {
    auth.userManager.getUser().then((u) => {
      setLoggedIn(loginService.checkUser(u));
    });
    setTimeout(() => {
      document.getElementById('fallbackBtn')?.classList.add('show-btn');
    }, 2000);
  }

  useEffect(() => {
    if (loginService.canAutoSignIn()) {
      sessionStorage.setItem('loginInProgress', 'true');
      auth.userManager.signinSilent().then(
        (u) => {
          setLoggedIn(loginService.checkUser(u));
        },
        () => {
          loginService.clearPermanentToken();
          setLoggedIn(false);
          auth.signIn();
        },
      );
    }
  }, [auth, loginService]);

  useEffect(() => {
    const excludedPages: string[] = [];

    setLoggedIn(loginService.checkLoggedIn);
    auth.userManager.getUser().then((u: any) => {
      if (!u?.expired) {
        if (loggedIn) {
          setUser(u);
          navigate('/replenish-suggestions');
        }
      } else {
        loginService.logout().then(() => {
          handleRedirectToLogin();
        });
      }
    });
  }, [loggedIn]);

  const handleRedirectToLogin = (): void => {
    window.location.replace('/');
    localStorage.clear();
    sessionStorage.clear();
  };

  const isOutletAllowToShown = (): boolean => {
    return loggedIn;
  };

  return isOutletAllowToShown() ? (
    outlet
  ) : (
    <div className="registration-fallback-root">
      <Button
        id="fallbackBtn"
        className="fallback-btn"
        onClick={handleRedirectToLogin}
      >
        {t('button.backToLoginPage')}
      </Button>
    </div>
  );
}
