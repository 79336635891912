import { jwtDecode } from 'jwt-decode';
import { WebStorageStateStore } from 'oidc-client-ts';
import {
  AuthContextProps,
  AuthProviderProps,
  useAuth,
  User,
  UserManager,
} from 'oidc-react';
import { SecureLocalStorageStorage } from '../utils/SecureLocalStorageStorage';

const useLoginService = () => {
  const auth: AuthContextProps = useAuth();
  const LOGIN_IN_PROGRESS_FLAG = 'loginInProgress';

  const clearLocalUser = () => {
    auth.userManager.removeUser();
    auth.userManager.clearStaleState();
    sessionStorage.removeItem(LOGIN_IN_PROGRESS_FLAG);
  };
  const isTokenValid = (token: string | null): boolean => {
    if (!token) return false;
    try {
      const decoded: any = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    } catch (error) {
      return false;
    }
  };

  return {
    getAccessToken: () => {
      return auth.userData?.access_token ?? null;
    },

    checkUser: (userData: User | null) => {
      if (userData != null) {
        sessionStorage.removeItem(LOGIN_IN_PROGRESS_FLAG);
        return true;
      }
      // else if (!auth.isLoading) {
      //   if (!sessionStorage.getItem(LOGIN_IN_PROGRESS_FLAG)) {
      //     sessionStorage.setItem(LOGIN_IN_PROGRESS_FLAG, 'true');
      //     auth.signIn();
      //   }
      // }
      return false;
    },

    clearPermanentToken: () => {
      auth.userManager.removeUser();
    },

    checkLoggedIn: () => {
      return !!auth.userData;
    },
    isTokenValid,
    canAutoSignIn: () => {
      const loginInProgress =
        sessionStorage.getItem(LOGIN_IN_PROGRESS_FLAG) === 'true';
      const token = localStorage.getItem(
        '@secure.s.soidc.user:' +
          auth.userManager.settings.authority +
          ':' +
          auth.userManager.settings.client_id,
      );
      return loginInProgress && token != null && !auth.isLoading;
    },

    logout: (): Promise<void> => {
      sessionStorage.removeItem(LOGIN_IN_PROGRESS_FLAG);
      const url = `${auth.userManager.settings.authority}/protocol/openid-connect/logout`;
      const refresh_token = auth.userData?.refresh_token;
      const formData = new URLSearchParams();
      formData.append('client_id', auth.userManager.settings.client_id);
      formData.append('refresh_token', refresh_token ?? '');

      return fetch(`${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString(),
        credentials: 'include',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          clearLocalUser();
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error);
        });
    },
  };
};

export const initKiipUserManager = (props: AuthProviderProps): UserManager => {
  if (props.userManager) {
    return props.userManager;
  }
  const userStorage = new WebStorageStateStore({
    prefix: 'soidc.',
    store: new SecureLocalStorageStorage(),
  });
  const stateStorage = new WebStorageStateStore({ store: sessionStorage });

  const {
    authority,
    clientId,
    clientSecret,
    redirectUri,
    silentRedirectUri,
    postLogoutRedirectUri,
    responseType,
    scope,
    automaticSilentRenew,
    loadUserInfo,
    popupWindowFeatures,
    popupRedirectUri,
    popupWindowTarget,
    extraQueryParams,
    metadata,
  } = props;
  return new UserManager({
    authority: authority ?? '',
    client_id: clientId ?? '',
    client_secret: clientSecret,
    redirect_uri: redirectUri ?? '',
    silent_redirect_uri: silentRedirectUri ?? redirectUri,
    post_logout_redirect_uri: postLogoutRedirectUri ?? redirectUri,
    response_type: responseType ?? 'code',
    scope: scope ?? 'openid',
    loadUserInfo: loadUserInfo ?? true,
    popupWindowFeatures: popupWindowFeatures,
    popup_redirect_uri: popupRedirectUri,
    popupWindowTarget: popupWindowTarget,
    automaticSilentRenew,
    extraQueryParams,
    metadata: metadata,
    userStore: userStorage,
    stateStore: stateStorage,
  });
};

export default useLoginService;
