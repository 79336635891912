import { User } from "oidc-react";

const LOGIN_IN_PROGRESS_FLAG = "loginInProgress";

const useSignInListener = () => {
  return {
    onSignIn: (userData: User | null) => {
      console.warn("onSignIn");
      if (userData != null) {
        sessionStorage.removeItem(LOGIN_IN_PROGRESS_FLAG);
      }
    },
  };
};
export default useSignInListener;
