import { User } from 'oidc-react';
import React, { createContext, useState } from 'react';

interface UserContextProps {
  user: User | undefined;
  setUser: (user: User) => void;
  inventoryId: string | undefined;
  setInventoryId: (inventoryId: string) => void;
}

const defaultUserContext: UserContextProps = {
  user: {} as User,
  setUser() {},
  inventoryId: {} as string,
  setInventoryId() {},
};

export const UserContext = createContext<UserContextProps>(defaultUserContext);

export const UserContextProvider: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const [user, setUser] = useState<User>();
  const [inventoryId, setInventoryId] = useState<string>();

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        inventoryId,
        setInventoryId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
