import secureLocalStorage from 'react-secure-storage';

export class SecureLocalStorageStorage implements Storage {
    private _keys: string[] = [];

    get length() {
        return this._keys.length;
    }

    clear(): Promise<void> {
        this._keys.forEach(k => secureLocalStorage.removeItem(k));
        this._keys = [];
        return Promise.resolve();
    }

    getItem(key: string): string | null {
        return secureLocalStorage.getItem(key) ? '' + secureLocalStorage.getItem(key) : null;
    }

    key(index: number): string | null {
        if (this._keys.length > index) {
            return this._keys[index];
        }
        return null;
    }

    removeItem(key: string): Promise<void> {
        secureLocalStorage.removeItem(key);
        if (this._keys.includes(key)) {
            this._keys = this._keys.splice(this._keys.indexOf(key), 1);
        }
        return Promise.resolve();
    }

    setItem(key: string, value: string): Promise<void> {
        secureLocalStorage.setItem(key, value);
        this._keys.push(key);
        return Promise.resolve();
    }
}
