import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Menu,
  MenuProps,
  Space,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IReplenishSuggestion } from '../../@types/replenish-suggestion.interface';
import { SuggestionStatus } from '../../@types/suggestion-status.enum';
import useRestApi from '../../api';
import useLoginService from '../../api/useLoginService';
import logo from '../../assets/images/RS_logo.svg';
import { UserContext } from '../../context/userContext';
import Splash from '../splash';
import './styles.scss';
import ReplenishSuggestionsTable from './table/replenish-suggestions-table.component';
const { Title } = Typography;

const ReplenishSuggestionsDashboard: React.FC = () => {
  const { t } = useTranslation('replenishSuggestions');
  const [dataSource, setDataSource] = useState<IReplenishSuggestion[]>([]);
  const { user } = useContext(UserContext);
  const { replenishSuggestionsApi } = useRestApi();
  const loginService = useLoginService();

  const handleLogout = async () => {
    await loginService.logout();
    window.location.replace('/');
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const fetchApiData = async () => {
    try {
      const data =
        (await replenishSuggestionsApi.getAllReplenishSuggestions()) || [];
      const sortedData = data.sort((a, b) => {
        return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
      });

      // Uncomment this to only load future values
      // const upcomingSuggestions = sortedData.filter((rs) => {
      //   const dueDateDifferenceInDays = dayjs(rs.dueDate)
      //     .startOf('day')
      //     .diff(dayjs().startOf('day'), 'day');
      //   return dueDateDifferenceInDays;
      // });
      setDataSource(sortedData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchApiData();
  }, []);

  const items: MenuProps['items'] = [
    {
      label: 'Logout',
      key: 'logout',
      icon: <LogoutOutlined />,
    },
  ];
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    // message.info('Click on menu item.');
    console.log('click', e);
    if (e.key === 'logout') {
      handleLogout();
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <>
      <div className="replenish-suggestions-container">
        <Splash redirect={false}>
          <Typography>
            {/* <Title>{t('heading.title')}</Title> */}
            <Title level={3} style={{ margin: 0 }}>
              {user ? (
                <Card>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {user.profile.aud === 'roeststaette' ? (
                      <div
                        style={{ marginLeft: 'auto', marginRight: '-100px' }}
                      >
                        <a href="/replenish-suggestions">
                          <img
                            src={logo}
                            style={{
                              width: '100px',
                              color: 'black',
                            }}
                          />
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                    <div style={{ marginLeft: 'auto' }}>
                      <Dropdown menu={menuProps} placement="topRight">
                        <Button icon={<UserOutlined />}>
                          <Space>
                            {user.profile.preferred_username
                              ?.charAt(0)
                              .toUpperCase() ?? 'U'}
                            <DownOutlined />
                          </Space>
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Space>
                      <Badge
                        count={
                          dataSource.filter(
                            (rs) => rs.status === SuggestionStatus.PENDING,
                          ).length
                        }
                        showZero
                        color="#faad14"
                      />
                      {t('heading.pendingReplenishSuggestions')}
                    </Space>
                  </div>
                </Card>
              ) : (
                'Undefined'
              )}
            </Title>

            <br />
            <br />
            <ReplenishSuggestionsTable
              key="replenish-suggestions-table"
              dataSource={dataSource}
              fetchApiData={fetchApiData}
              replenishSuggestionsApi={replenishSuggestionsApi}
            />
          </Typography>
        </Splash>
      </div>
    </>
  );
};
ReplenishSuggestionsDashboard.propTypes = {
  data: PropTypes.any,
};

export default ReplenishSuggestionsDashboard;
