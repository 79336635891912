import axios, { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { IReplenishSuggestionItem } from '../@types/replenish-suggestion-item.interface';
import { IReplenishSuggestion } from '../@types/replenish-suggestion.interface';
import useLoginService from './useLoginService';
const apiUrl = process.env.REACT_APP_API_ENDPOINTS;

export interface ReplenishSuggestionsApi {
  getAllReplenishSuggestions: () => Promise<IReplenishSuggestion[] | undefined>;
  cancelReplenishSuggestion: (
    rs: IReplenishSuggestion,
  ) => Promise<IReplenishSuggestion[] | undefined>;
  cancelReplenishSuggestionItem: (
    rsi: IReplenishSuggestionItem,
  ) => Promise<IReplenishSuggestion[] | undefined>;
  modifyReplenishSuggestionItemAmount: (
    rsi: IReplenishSuggestionItem,
  ) => Promise<IReplenishSuggestionItem | undefined>;
}

const useRestApi = (): { replenishSuggestionsApi: ReplenishSuggestionsApi } => {
  const loginService = useLoginService();

  const getHeaders = () => {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
      'Access-Control-Allow-Origin': `${process.env.REACT_APP_APP_BASE_URL}`,
      'Cache-Control': 'no-cache',
    };
  };

  const getAccessToken = (): string | null => {
    return loginService.getAccessToken();
  };

  async function validateTokenAndFetch<T>(
    fetchFunction: () => Promise<T>,
  ): Promise<T | undefined> {
    const token = getAccessToken();
    if (!loginService.isTokenValid(token)) {
      await loginService.logout();
      window.location.replace('/');
      return;
    }
    return fetchFunction();
  }

  const checkForErrors = (response: AxiosResponse) => {
    if (!response.status.toString().startsWith('2')) {
      if (response.status === 401) {
        loginService.logout().then(() => {
          window.location.replace('/');
        });
      }
      console.error('There was a problem with the fetch operation:', response);
      throw new Error('Network response was not ok');
    }
  };

  const getAllReplenishSuggestions = useCallback(async (): Promise<
    IReplenishSuggestion[] | undefined
  > => {
    return validateTokenAndFetch<IReplenishSuggestion[]>(async () => {
      const response = await axios.get<IReplenishSuggestion[]>(
        `${apiUrl}/replenishSuggestion`,
        {
          headers: getHeaders(),
        },
      );
      checkForErrors(response);
      return response.data;
    });
  }, [apiUrl]);

  const cancelReplenishSuggestion = useCallback(
    async (
      rs: IReplenishSuggestion,
    ): Promise<IReplenishSuggestion[] | undefined> => {
      return validateTokenAndFetch<IReplenishSuggestion[]>(async () => {
        const response = await axios.delete<IReplenishSuggestion[]>(
          `${apiUrl}/replenishSuggestion/${rs.id}`,
          {
            headers: getHeaders(),
          },
        );
        checkForErrors(response);
        return response.data;
      });
    },
    [apiUrl],
  );

  const cancelReplenishSuggestionItem = useCallback(
    async (
      rsi: IReplenishSuggestionItem,
    ): Promise<IReplenishSuggestion[] | undefined> => {
      return validateTokenAndFetch<IReplenishSuggestion[]>(async () => {
        const response = await axios.delete<IReplenishSuggestion[]>(
          `${apiUrl}/replenishSuggestion/item/${rsi.id}`,
          {
            headers: getHeaders(),
          },
        );
        checkForErrors(response);
        return response.data;
      });
    },
    [apiUrl],
  );

  const modifyReplenishSuggestionItemAmount = useCallback(
    async (
      rsi: IReplenishSuggestionItem,
    ): Promise<IReplenishSuggestionItem | undefined> => {
      return validateTokenAndFetch<IReplenishSuggestionItem>(async () => {
        const response = await axios.put<IReplenishSuggestionItem>(
          `${apiUrl}/replenishSuggestion/item/${rsi.id}`,
          {
            amount: rsi.pieces,
          },
          {
            headers: getHeaders(),
          },
        );
        checkForErrors(response);
        return response.data;
      });
    },
    [apiUrl],
  );

  return {
    replenishSuggestionsApi: {
      getAllReplenishSuggestions,
      cancelReplenishSuggestion,
      cancelReplenishSuggestionItem,
      modifyReplenishSuggestionItemAmount,
    },
  };
};

export default useRestApi;
