const login = {
  // Login
  catchphrase: {
    fistLineString: 'kiip it simple,',
    secondLineString: 'kiip it stocked.',
  },
  button: {
    telephoneRegistration: 'Weiter mit Telefonnummer',
    emailRegistration: 'Weiter mit E-Mail',
    backToLoginPage: 'Zurück zur Anmeldeseite',
  },
};

export default login;
