import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Splash from './components/splash';

import { AuthProvider } from 'oidc-react';
import { initKiipUserManager } from './api/useLoginService';
import useSignInListener from './api/useSignInListener';
import OfflineStatus from './components/common/OfflineStatus';
import ReplenishSuggestionsDashboard from './components/replenish-suggestions';
import ProtectedRoute from './protected.route';

const Router: React.FC = () => {
  const signInListener = useSignInListener();

  return (
    <BrowserRouter>
      <OfflineStatus />
      <AuthProvider
        userManager={initKiipUserManager({
          authority: `${process.env.REACT_APP_KEYCLOAK_URI}/realms/kiip`,
          clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENT}`,
          redirectUri: `${process.env.REACT_APP_APP_BASE_URL}/replenish-suggestions`,
          autoSignIn: false,
          onSignIn: signInListener.onSignIn,
        })}
      >
        <Routes>
          <Route path="/" element={<Splash redirect={true} />} />

          <Route
            path="*"
            element={<Navigate to="/replenish-suggestions" replace />}
          />
          <Route
            path="/replenish-suggestions"
            element={
              <ProtectedRoute outlet={<ReplenishSuggestionsDashboard />} />
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
